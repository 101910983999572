import * as ActionType from './contant'

const initState = {}

const initializeState = () => {
  const state = initState
  return state
}

const reducer = (state = initializeState(), action?: any) => {
  const { type, value } = action

  switch (type) {
    case ActionType.SET_ROLES:
      return { ...state, ...value }
    case ActionType.UPDATE_ROLES:
      return { ...state, ...value }
    case ActionType.CLEAR_ROLES:
      return {}
    default:
      return state
  }
}

export default reducer

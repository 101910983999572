import { LoadingOutlined } from '@ant-design/icons'

const Loading = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <LoadingOutlined style={{ fontSize: '32px' }} />
    </div>
  )
}

export default Loading

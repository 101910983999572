/* eslint-disable import/no-anonymous-default-export */
import roles from './roles/reducer'
import purchaseTransaction from './purchaseTransaction/reducer'
import dropshipperTransaction from './dropshipperTransaction/reducer'
import salesTransaction from './salesTransaction/reducer'
import wholesaleTransaction from './wholesaleTransaction/reducer'
import returnTransaction from './returnTransaction/reducer'
import refreshNotication from './refreshNotification/reducer'
import stockopnameTransaction from './stockopnameTransaction/reducer'

export default {
  roles,
  purchaseTransaction,
  dropshipperTransaction,
  salesTransaction,
  wholesaleTransaction,
  returnTransaction,
  refreshNotication,
  stockopnameTransaction,
}

import { lazy } from 'react'

const Dashboard = lazy(() => import('src/views/dashboard'))
const Stock = lazy(() => import('src/views/stock'))
const Users = lazy(() => import('src/views/users'))
const UsersAdd = lazy(() => import('src/views/users/add'))
const UsersEdit = lazy(() => import('src/views/users/edit'))
const Suppliers = lazy(() => import('src/views/suppliers'))
const SuppliersAdd = lazy(() => import('src/views/suppliers/add'))
const SuppliersEdit = lazy(() => import('src/views/suppliers/edit'))
const DropShipper = lazy(() => import('src/views/dropShipper'))
const FinancialReport = lazy(() => import('src/views/financialReport'))
const SalesReport = lazy(() => import('src/views/salesReport'))
const PurchaseReport = lazy(() => import('src/views/purchaseReport'))
const RekturReport = lazy(() => import('src/views/rekturReport'))
const Category = lazy(() => import('src/views/category'))
const CategoryAdd = lazy(() => import('src/views/category/add'))
const CategoryEdit = lazy(() => import('src/views/category/edit'))
const Item = lazy(() => import('src/views/item'))
const ItemAdd = lazy(() => import('src/views/item/add'))
const ItemEdit = lazy(() => import('src/views/item/edit'))
const PurchaseTransaction = lazy(() => import('src/views/purchaseTransaction'))
const PurchaseTransactionList = lazy(
  () => import('src/views/purchaseTransaction/transactionList')
)
const PurchaseTransactionEdit = lazy(
  () => import('src/views/purchaseTransaction/edit')
)
const StokOpname = lazy(() => import('src/views/stokOpname'))
const StokOpnameList = lazy(
  () => import('src/views/stockOpnameTransactionList')
)
const StokOpnameEdit = lazy(() => import('src/views/stokOpname/edit'))
const SalesTransaction = lazy(() => import('src/views/salesTransaction'))
const SalesTransactionList = lazy(
  () => import('src/views/salesTransaction/transactionList')
)
const SalesTransactionEdit = lazy(
  () => import('src/views/salesTransaction/edit')
)
const GrosirTransaction = lazy(() => import('src/views/grosirTransaction'))
const GrosirTransactionList = lazy(
  () => import('src/views/grosirTransaction/transactionList')
)
const GrosirTransactionEdit = lazy(
  () => import('src/views/grosirTransaction/edit')
)
const DropshipperTransaction = lazy(
  () => import('src/views/dropshipperTransaction')
)
const DropshipperTransactionList = lazy(
  () => import('src/views/dropshipperTransaction/transactionList')
)
const DropshipperTransactionEdit = lazy(
  () => import('src/views/dropshipperTransaction/edit')
)
const ReturnTransaction = lazy(() => import('src/views/returnTransaction'))
const OrderData = lazy(() => import('src/views/orderData'))
const OrderDataDetail = lazy(() => import('src/views/orderData/detail'))
const Barcode = lazy(() => import('src/views/barcode'))
const PaymentMethod = lazy(() => import('src/views/paymentMethod'))
const Discount = lazy(() => import('src/views/discount'))
const Spending = lazy(() => import('src/views/spending'))
const Stores = lazy(() => import('src/views/stores'))
const StoresAdd = lazy(() => import('src/views/stores/add'))
const StoresEdit = lazy(() => import('src/views/stores/edit'))
const Notifications = lazy(() => import('src/views/notifications'))
const Banner = lazy(() => import('src/views/banners'))
const BannerAdd = lazy(() => import('src/views/banners/add'))
const BannerEdit = lazy(() => import('src/views/banners/edit'))
const InfoReseller = lazy(() => import('src/views/infoReseller'))
const InfoResellerAdd = lazy(() => import('src/views/infoReseller/add'))
const InfoResellerEdit = lazy(() => import('src/views/infoReseller/edit'))

const router = [
  {
    name: 'Dashboard',
    path: '/home',
    pathName: '/home',
    component: Dashboard,
  },
  {
    name: 'Stok barang',
    path: '/stock',
    pathName: '/stock',
    component: Stock,
  },
  {
    name: 'Manajemen Kategori',
    path: '/category',
    pathName: '/category',
    component: Category,
  },
  {
    name: 'Manajemen Kategori',
    path: '/category/add',
    pathName: '/category/add',
    component: CategoryAdd,
  },
  {
    name: 'Manajemen Kategori',
    path: '/category/edit/:id',
    pathName: '/category/edit',
    component: CategoryEdit,
  },
  {
    name: 'Manajemen Barang',
    path: '/item',
    pathName: '/item',
    component: Item,
  },
  {
    name: 'Manajemen Barang',
    path: '/item/add',
    pathName: '/item/add',
    component: ItemAdd,
  },
  {
    name: 'Manajemen Barang',
    path: '/item/edit/:id',
    pathName: '/item/edit',
    component: ItemEdit,
  },
  {
    name: 'Manajemen User',
    path: '/users',
    pathName: '/users',
    component: Users,
  },
  {
    name: 'Manajemen User',
    path: '/users/add',
    pathName: '/users/add',
    component: UsersAdd,
  },
  {
    name: 'Manajemen User',
    path: '/users/edit/:id',
    pathName: '/users/edit',
    component: UsersEdit,
  },
  {
    name: 'Manajemen Supplier',
    path: '/suppliers',
    pathName: '/suppliers',
    component: Suppliers,
  },
  {
    name: 'Manajemen Supplier',
    path: '/suppliers/add',
    pathName: '/suppliers/add',
    component: SuppliersAdd,
  },
  {
    name: 'Manajemen Supplier',
    path: '/suppliers/edit/:id',
    pathName: '/suppliers/edit',
    component: SuppliersEdit,
  },

  {
    name: 'Manajemen Dropshipper',
    path: '/drop-shipper',
    pathName: '/drop-shipper',
    component: DropShipper,
  },
  {
    name: 'Transaksi Pembelian',
    path: '/purchase-transaction',
    pathName: '/purchase-transaction',
    component: PurchaseTransaction,
  },
  {
    name: 'Transaksi Pembelian',
    path: '/purchase-transaction/list',
    pathName: '/purchase-transaction/list',
    component: PurchaseTransactionList,
  },
  {
    name: 'Transaksi Pembelian',
    path: '/purchase-transaction/edit/:id',
    pathName: '/purchase-transaction/edit',
    component: PurchaseTransactionEdit,
  },
  {
    name: 'Penjualan Ritel',
    path: '/sales-transaction',
    pathName: '/sales-transaction',
    component: SalesTransaction,
  },
  {
    name: 'Penjualan Ritel',
    path: '/sales-transaction/list',
    pathName: '/sales-transaction/list',
    component: SalesTransactionList,
  },
  {
    name: 'Penjualan Ritel',
    path: '/sales-transaction/edit/:id',
    pathName: '/sales-transaction/edit',
    component: SalesTransactionEdit,
  },
  {
    name: 'Stok Opname',
    path: '/stok-opname',
    pathName: '/stok-opname',
    component: StokOpname,
  },
  {
    name: 'Stok Opname',
    path: '/stok-opname/list',
    pathName: '/stok-opname/list',
    component: StokOpnameList,
  },
  {
    name: 'Stok Opname',
    path: '/stok-opname/edit/:id',
    pathName: '/stok-opname/edit',
    component: StokOpnameEdit,
  },
  {
    name: 'Penjualan Grosir',
    path: '/grosir-transaction',
    pathName: '/grosir-transaction',
    component: GrosirTransaction,
  },
  {
    name: 'Penjualan Grosir',
    path: '/grosir-transaction/list',
    pathName: '/grosir-transaction/list',
    component: GrosirTransactionList,
  },
  {
    name: 'Penjualan Grosir',
    path: '/grosir-transaction/edit/:id',
    pathName: '/grosir-transaction/edit',
    component: GrosirTransactionEdit,
  },
  {
    name: 'Penjualan Dropshipper',
    path: '/dropshipper-transaction',
    pathName: '/dropshipper-transaction',
    component: DropshipperTransaction,
  },
  {
    name: 'Penjualan Dropshipper',
    path: '/dropshipper-transaction/list',
    pathName: '/dropshipper-transaction/list',
    component: DropshipperTransactionList,
  },
  {
    name: 'Penjualan Dropshipper',
    path: '/dropshipper-transaction/edit/:id',
    pathName: '/dropshipper-transaction/edit',
    component: DropshipperTransactionEdit,
  },
  {
    name: 'Transaksi Retur Barang',
    path: '/return-transaction',
    pathName: '/return-transaction',
    component: ReturnTransaction,
  },
  {
    name: 'Data Pesanan',
    path: '/order',
    pathName: '/order',
    component: OrderData,
  },
  {
    name: 'Data Pesanan',
    path: '/order/detail/:id',
    pathName: '/order/detail',
    component: OrderDataDetail,
  },
  {
    name: 'Laporan Keuangan',
    path: '/financial-report',
    pathName: '/financial-report',
    component: FinancialReport,
  },
  {
    name: 'laporan Penjualan',
    path: '/sales-report',
    pathName: '/sales-report',
    component: SalesReport,
  },
  {
    name: 'Laporan Pembelian',
    path: '/purchase-report',
    pathName: '/purchase-report',
    component: PurchaseReport,
  },
  {
    name: 'Laporan Retur',
    path: '/rektur-report',
    pathName: '/rektur-report',
    component: RekturReport,
  },
  {
    name: 'Cetak Barcode',
    path: '/barcode',
    pathName: '/barcode',
    component: Barcode,
  },
  {
    name: 'Metode Pembayaran',
    path: '/payment-method',
    pathName: '/payment-method',
    component: PaymentMethod,
  },
  {
    name: 'Diskon',
    path: '/discount',
    pathName: '/discount',
    component: Discount,
  },
  {
    name: 'Pencatatan Uang Keluar',
    path: '/spending',
    pathName: '/spending',
    component: Spending,
  },
  {
    name: 'Toko',
    path: '/stores',
    pathName: '/stores',
    component: Stores,
  },
  {
    name: 'Toko',
    path: '/stores/add',
    pathName: '/stores/add',
    component: StoresAdd,
  },
  {
    name: 'Toko',
    path: '/stores/edit/:id',
    pathName: '/stores/edit',
    component: StoresEdit,
  },
  {
    name: 'Notifikasi',
    path: '/notifications',
    pathName: '/notifications',
    component: Notifications,
  },
  {
    name: 'Banner',
    path: '/banner',
    pathName: '/banner',
    component: Banner,
  },
  {
    name: 'Banner',
    path: '/banner/add',
    pathName: '/banner/add',
    component: BannerAdd,
  },
  {
    name: 'Banner',
    path: '/banner/edit/:id',
    pathName: '/banner/edit',
    component: BannerEdit,
  },
  {
    name: 'Info Reseller',
    path: '/info-reseller',
    pathName: '/info-reseller',
    component: InfoReseller,
  },
  {
    name: 'Info Reseller',
    path: '/info-reseller/add',
    pathName: '/info-reseller/add',
    component: InfoResellerAdd,
  },
  {
    name: 'Info Reseller',
    path: '/info-reseller/edit/:id',
    pathName: '/info-reseller/edit',
    component: InfoResellerEdit,
  },
]

export default router

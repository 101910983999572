import * as ActionType from './contant'
import Cookie from 'src/utils/cookie'

const keyCookie = 'wholesaleTransaction'

const initializeState = () => {
  const state = Cookie.get(keyCookie)
  return state
}

const reducer = (state = initializeState(), action?: any) => {
  const { type, value } = action

  switch (type) {
    case ActionType.SET_WHOLESALE_TRANSACTION:
      Cookie.set(keyCookie, value)
      return value
    case ActionType.UPDATE_WHOLESALE_TRANSACTION:
      const tempValue = state ? [...state] : []
      tempValue.push(value)
      Cookie.set(keyCookie, tempValue)
      return tempValue
    case ActionType.CLEAR_WHOLESALE_TRANSACTION:
      Cookie.set(keyCookie, [])
      return []
    default:
      return state || []
  }
}

export default reducer

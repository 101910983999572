import { lazy } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import router from 'src/router/router'
import Cookie from 'src/utils/cookie'

const Layout = lazy(() => import('./layout/layout'))
const Login = lazy(() => import('./views/auth/login/login'))
const NotFound = lazy(() => import('./components/notFound'))
const ResetPassword = lazy(
  () => import('./views/changePassword/changePassword')
)

const LayoutContainer = () => (
  <Layout>
    <Switch>
      {router?.map((e, i) => (
        <Route component={e?.component} path={e?.path} key={i} exact />
      ))}
      <Route exact path="/">
        {Cookie.get('auth')?.user?.role === 'Owner' ? (
          <Redirect to="/home" />
        ) : (
          <Redirect to="/stock" />
        )}
      </Route>
      <Route component={NotFound} path="*" />
    </Switch>
  </Layout>
)

const FrontAndLayoutContainer = () => (
  <Switch>
    <Route component={Login} exact path="/login" />
    <Route component={ResetPassword} exact path="/reset-password" />
    <Route component={LayoutContainer} path="/" />
  </Switch>
)

const RouteHandler = () => (
  <BrowserRouter>
    <Route component={FrontAndLayoutContainer} path="/" />
    <Route path="/" />
  </BrowserRouter>
)

export default RouteHandler

import * as ActionType from './contant'

const initState = {
  header: false,
  page: false,
}

const initializeState = () => {
  const state = initState
  return state
}

const reducer = (state = initializeState(), action?: any) => {
  const { type, value } = action

  switch (type) {
    case ActionType.SET_REFRESH_NOTIFICATION:
      return { ...state, ...value }
    case ActionType.UPDATE_REFRESH_NOTIFICATION:
      return { ...state, ...value }
    case ActionType.CLEAR_REFRESH_NOTIFICATION:
      return {}
    default:
      return state
  }
}

export default reducer

import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './redux/store'
import Loading from './components/loading'
import RouteHandler from './RouteHandler'
import moment from 'moment'
import 'moment/locale/id'
import * as serviceWorker from './serviceWorker'

import 'antd/dist/antd.less'
import './styles/app.scss'

moment.locale('id')

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/firebase-messaging-sw.js')
    .catch(function (error) {
      // Failed registration, service worker won’t be installed
      console.log('Whoops. Service worker registration failed, error:', error)
    })
}
const App = () => (
  <Provider store={store}>
    <Suspense fallback={<Loading />}>
      <RouteHandler />
    </Suspense>
  </Provider>
)

ReactDOM.render(<App />, document.getElementById('root'))
serviceWorker.unregister()

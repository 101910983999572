import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import { cookieKey } from 'src/constants/globalVariable'

const Cookie = {
  set: (key: string, value: any) => {
    const ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(value),
      cookieKey
    ).toString()
    Cookies.set(key, ciphertext)
  },
  get: (key: string) => {
    const data = Cookies.get(key)
    const value = data ? CryptoJS.AES.decrypt(`${data}`, cookieKey).toString(CryptoJS.enc.Utf8) : null
    return value ? JSON.parse(value) : null
  },
  clear: (key: string) => {
    Cookies.remove(key)
  },
}

export default Cookie
